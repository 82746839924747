<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12">
      <div>
        <div class="card">
          <div class="card-header" v-if="isAdmin">
            <a-button type="primary" @click="goToNew">
              + Tambah Laboratorium
            </a-button>
          </div>
          <div class="card-body">
            <TabelLaboratorium />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabelLaboratorium from '@/components/tables/laboratorium'
import router from '@/router'

export default {
  components: {
    TabelLaboratorium,
  },
  data: function () {
    return {
      isAdmin: false,
    }
  },
  created() {
    this.getRole()
  },
  methods: {
    getRole() {
      const role = window.localStorage.getItem('role')
      // console.log(role)
      if (role === 'admin') {
        this.isAdmin = true
      }
    },
    goToNew() {
      router.push('/laboratorium/tambah')
    },
  },
}
</script>
